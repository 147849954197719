body {
  margin: 0;
  font-family: -apple-system, "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dx-widget input,
.dx-widget textarea {
  font-family: -apple-system, "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blurring.dimmed.dimmable> :not(.dimmer):not(.dx-overlay-wrapper):not(.dx-overlay-content):not(.dx-overlay) {
  filter: blur(15px) !important;
}

.blurring.dimmed.dimmable> :not(.dimmer) {
  filter: unset !important;
}

.blurring>.dimmer {
  background: rgba(211, 211, 211, 0.5) !important;
}

.ui>.content {
  background: transparent !important;
}

.ui>.header {
  background: transparent !important;
}

.ui>.actions {
  background: transparent !important;
}

.ui.modal {
  /* background: inherit!important; */
  /* background: rgba(255, 255, 255, 0.8) !important; */
  /* box-shadow: 1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%) !important; */
  /* border-radius: 0.5rem !important; */
  box-shadow: none;
  background-color: #DADDE5 !important;
}


.dx-datagrid .dx-row>td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 13px;
  line-height: 16px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  line-height: 16px;
  font-weight: 700 !important;
  font-size: 12px;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
  /* background: linear-gradient(to bottom, #4fc5fa 0%, #328af7 100%); */
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
  color: white !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)>td {
  border-top: 1px solid #f5f5f5 !important;
}

.dx-header-row>td:first-of-type {
  border-right: 1px solid #e0e0e0;
}

.dx-header-row>td {
  border-right: 1px solid #e0e0e0 !important;
}

.dx-datagrid .dx-header-filter:before {
  font-size: 10px;
}

.dx-datagrid .dx-header-filter,
.dx-datagrid .dx-sort-down,
.dx-datagrid .dx-sort-up,
.dx-datagrid-container .dx-header-filter,
.dx-datagrid-container .dx-sort-down,
.dx-datagrid-container .dx-sort-up {
  line-height: 10px !important;
}

.dx-datagrid-filter-panel {
  padding: 6px 10px !important;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
  color: rgba(0, 0, 0, .54) !important;
  font-weight: 500;
  ;
}

.dx-datagrid-filter-panel .dx-icon-filter {
  color: rgba(0, 0, 0, .54) !important;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 20px !important;
  line-height: 20px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  height: 20px !important;
  line-height: 0px !important;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
  height: 20px !important;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 0px !important;
}

.dx-datagrid-table-fixed {
  background-color: #FAFAFA !important;
}



.header-title {
  padding-left: 20px !important;
}

.side-nav-outer-toolbar {
  background-color: #FFF;
}

.dx-widget {
  font-family: -apple-system, "Inter", sans-serif !important;
}

.brand-header {
  /* background-color: #0078D4!important; */
  background-color: #B6D1FF !important;
  /* color: #fff !important; */
}

.brand-header>.dx-toolbar-items-container {
  height: 80px !important;
}

.user-info .user-name {
  /* color: #fff !important; */
}

.header-component {
  box-shadow: none !important;
}

.header-component .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
  color: #fff !important;
}

.system-other-list {
  width: 400px !important;
  max-width: 400px !important;
}

.padding-1rem {
  padding: 1rem;
}

.width-400 {
  width: 400px;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.system-other {
  text-align: center;
  border-radius: 10px;
  color: black !important;
}

.system-other>a {
  color: black !important;
}

.system-other:hover {
  background-color: #f2f2f2;
}

.system-other-img {
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.system-other-name {
  margin-top: 0.5rem;
}

.text-align-center {
  text-align: center;
}

.width-100-p {
  width: 100%;
}

.width-50-p {
  width: 40%;
}

.app-name {
  font-size: 20px;
  font-weight: 400;
  color: #333;
  white-space: pre-line;
  pointer-events: all;
}

.homescreen {
  overflow: hidden;
}

.p-fluid {
  width: 100%;
}

.p-white-color {
  color: white !important;
}

.bg-grey {
  background-color: hsla(0, 0%, 74.9%, .15);
}

.padding-2rem {
  padding: 2rem;
}

.padding-top-2rem {
  padding-top: 2rem
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.hide {
  display: none;
}

.p-btn.p-prim-col:active {
  background-color: #3caa62;
}

.p-is-active {
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
}

.p-prim-col {
  /* background: linear-gradient(to bottom, #4fc5fa 0%, #0f75f5 100%); */
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
  background-size: 100%;
  border: 0;
  box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgba(0, 0, 0, .3);
  color: white !important;
}

.padding-top-25 {
  padding-top: 25px;
}

.ui.modal>.actions {
  padding: 0px !important;
}

.ui.modal>.actions {
  padding: 0px !important;
}

.ui.modal>.header {
  font-family: unset !important;
  padding: 8px !important;
  padding-left: 1.5rem !important;
}

.ui.modal>.header>p {
  font-weight: 500 !important;
  font-size: 15px !important;
}

.ui.modal>.content {
  padding-bottom: 40px;
}

.dx-editor-outlined {
  /* border-radius: 8px !important;
  background-color: rgba(0, 0, 0, .04) !important;
  border: none !important;
  box-shadow: none !important; */
  border-radius: 100px !important;
  background-color: #fff !important;
  border: 1px solid #C2C3CC;
  box-shadow: none !important;
}

.dx-editor-outlined.dx-state-focused {
  box-shadow: inset 0 0 0 1px #03a9f4 !important;

}

.text-mute {
  color: rgba(0, 0, 0, .5);
}

.ui.inline.loader.active,
.ui.inline.loader.visible {
  margin-left: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: unset !important;
}

.loading-login {
  width: 100px;
  height: 100px;
  margin-top: 45vh;
  margin-left: auto;
  margin-right: auto;
}

.dx-datagrid {
  color: rgba(0, 0, 0, 1) !important;
}

.dx-tag-content {
  color: black !important;
}

.dx-field-label {
  color: black !important;
}

.dx-texteditor-input {
  color: black !important;
  border-radius: 10px !important;
}

.help-text {
  color: rgba(0, 0, 0, .9);
  font-size: 12px;
}

.margin-top-10 {
  margin-top: 10px;
}

.field-group {
  margin-bottom: 1.5rem;
}

.field-label {
  font-size: 0.9rem;
  font-weight: 600;
}

.field-input {
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}

.small-textbox .dx-texteditor-input {
  padding: 8px !important;
  font-size: 1rem;
}

.dx-editor-outlined.dx-state-focused {
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}

.checkbox.bold>label {
  font-weight: bold;
}



.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}



.homescreen {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.homescreen-system-img {
  margin-left: auto;
  margin-right: auto;
}

.homescreen-appname {
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.homescreen-setting-account {
  color: #007aff !important;
  font-size: 20px;
  font-weight: 500;
}

.homescreen-setting-account-arrow {
  color: #007aff !important;
  fill: #007aff;
  margin-left: 5px;
}

.app-icon {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 24%;
}

.app-name {
  margin-top: 10px;
  font-weight: 500;
  color: #333;

}

.dx-tile-content {
  text-align: center;
}

.dx-tile {
  border: none !important;
}

.dx-tile.dx-state-active {
  background-color: unset !important;
}

.app-disabled {
  opacity: 0.45;
}

.legal-footer-content {
  text-align: center;
}

.legal-footer-content>span a {
  text-decoration: none;
}

.legal-footer-content>span {
  color: rgba(0, 0, 0, .3);
  line-height: 1.8;
  text-align: center;
}

.legal-footer-content>span .copyright,
.legal-footer-content>span a {
  color: rgba(0, 0, 0, .5);
}

.card-app.disabled .content {
  opacity: 0.6;
}

.portalApp {
  overflow: hidden;
}


@media (min-width: 576px) {
  .container-portal {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-portal {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-portal {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-portal {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-portal {
    max-width: 1320px;
  }
}

.container-portal {
  width: 100%;
  padding-right: .75rem;
  padding-left: .75rem;
  margin-right: auto;
  margin-left: auto;
}

.btn-submit {
  background-color: #3555F2;
}

.login-form .dx-button.dx-button-default {
  background-color: #3555F2!important;
  height: 72px;
  border-radius: 100px;
}